<template>
  <div class="components-container">
    <Orientations :type="type" :relatedId="relatedId" :userCanEdit="userCanEdit" />
    <FileUploader :type="type" :healthPlanId="relatedId" :userCanUpload="userCanUpload" :userCanDelete="userCanDelete"/>
  </div>
</template>

<script>
import Orientations from "./Components/Orientations.vue";
import FileUploader from "./Components/FileUploader.vue";
import { userHasPermission, getCurrentClinic } from '@/utils/localStorageManager'
export default {
  props: ['type', 'healthPlanId'],
	name: "OrientationsView",
	components: { Orientations, FileUploader },
	mounted() {
  },
	data() {
		return {
      clinic_id: getCurrentClinic().id
    }
	},

  computed: {
    relatedId() {
      if(this.type === 'health_plan'){
        return this.healthPlanId
      } else {
        return this.clinic_id
      }
    },
    userCanEdit() {
      if (this.type === 'health_plan') {
        return userHasPermission('OrConv2')
      } else {
        return userHasPermission('OrCli2')
      }
    },
    userCanUpload(){
      if (this.type === 'health_plan') {
        return userHasPermission('OrConv3')
      } else {
        return userHasPermission('OrCli3')
      }
    },
    userCanDelete(){
      if (this.type === 'health_plan') {
        return userHasPermission('OrConv4')
      } else {
        return userHasPermission('OrCli4')
      }
    }
  }
}
</script>

<style scoped>
.components-container {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
</style>
