<template>
  <div class="file-uploader" :class="{'list-only': listOnly}">
    <div v-if="!listOnly" class="file-uploader__title">
      <span class="section-title">Arquivos</span>
      <span class="section-subtitle">
        Utilize o campo abaixo para fazer upload de arquivos relacionados {{ type === 'clinic' ? 'a clínica' : 'ao convênio'}} como contratos, documentos, entre outros.
      </span>
    </div>
    <div class="file-uploader__container">
      <div v-if="userCanUpload && !uploading && !listOnly" class="file-uploader__input" @drop="addFiles" @dragover.prevent @drop.prevent>
        <FileUploadIcon class="upload-input-icon"/>
        <label for="set-file-content-input"><u>Clique para fazer upload</u> ou <b>arraste e solte</b></label>
        <input
            multiple
            id="set-file-content-input"
            name="set-file-content-input"
            type="file"
            accept=".jpeg, .jpg, .png, .gif, .pdf"
            max="3"
           @change="addFiles"
        >
        <span class="small">JPEG, JPG, PNG, GIF, PDF (max. 3MB)</span>
      </div>
      <div class="file-uploader__input" v-else-if="userCanUpload && uploading && !listOnly">
        <b-spinner variant="primary"></b-spinner>
        <span>Enviando arquivos. Por favor, aguarde...</span>
      </div>
      <div v-if="filesList.length && !loading" class="file-history">
        <div class="file-history__item" v-for="f in filesList" :key="f.id">
          <div class="file-history__item__id">
            <FileSent v-if="f.status && f.status === 'sent'" class="file-sent"/>
            <ProgressBar v-else-if="f.status && f.status === 'sending' && f.progress < 100" :value="f.progress"/>
            <FileUploadIcon v-else class="upload-icon" :class="{'failed-status': f.status === 'failed'}"/>
            <div class="file-info" :class="{'failed-status': f.status === 'failed', 'text-nowrap': !listOnly}" @click="showFile(f.path, f.name)">
              <span><b>{{ f.name }}</b></span>
              <span v-if="f.status" class="file-status">{{ returnFileSize(f.size) }} - {{ parseStatus(f.status) }}</span>
              <span v-else class="file-status">Enviado por {{f.uploaded_by}} - {{moment(f.uploaded_at).format('DD/MM/YY [às] HH:mm')}}</span>
            </div>
          </div>
          <div class="file-history__item__icons">
            <TrashBin v-if="userCanDelete" class="delete-file" @click="removeFile(f)"/>
          </div>
        </div>
      </div>
      <div v-else-if="loading" class="file-uploader__list">
        <b-spinner small variant="primary"></b-spinner>
        <span>Buscando arquivos. Por favor, aguarde...</span>
      </div>
      <div v-else>
        <span>Não há documentos disponíveis</span>
      </div>
    </div>
    <div v-if="showModal" class="modal">
      <div class="modal-content">
        <div class="modal-header">
          <span>Documentos Anexados</span>
          <Close class="modal-close" @click="closeModal" />
        </div>
        <div class="file-id">
          <span>{{ modalFileName }}</span>
          <span class="open-new-tab" @click="openNewTab(modalFileUrl)"><strong>Abrir em nova aba</strong><Export class="export-icon"/></span>
        </div>
        <div class="modal-body">
          <img alt="file-view" :src="modalFileUrl">
        </div>
        <div class="modal-footer">
          <button class="btn btn-primary" @click="closeModal">Concluir</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getCurrentClinic, getCurrentUser } from '@/utils/localStorageManager';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';

export default {
  components: {
    FileUploadIcon: () => import('@/assets/icons/upload_file.svg'),
    TrashBin: () => import('@/assets/icons/trash_bin.svg'),
    FileSent: () => import('@/assets/icons/file_sent.svg'),
    ProgressBar: () => import('./ProgressBar.vue'),
    Export: () => import('@/assets/icons/export.svg'),
    Close: () => import('@/assets/icons/close.svg')
  },
  name: 'FileUploader',
  props: ['healthPlanId', 'type', 'editOwnData', 'userCanUpload', 'userCanDelete', 'listOnly'],
  data() {
    return {
      clinic_id: getCurrentClinic().id,
      loading: false,
      uploading: false,
      filesList: [],
      filesToSend: [],
      modalFileUrl: '',
      modalFileName: '',
      showModal: false,
    }
  },
  mounted(){
    this.listFiles()
  },
  computed: {
  },

  methods: {
    moment,
    getCurrentUser,
    async addFiles(e) {
      const filesArray = []
      const files = e.target.files || e.dataTransfer.files
      if(Array.from(files).length) {
        for (let i = 0; i < files.length; i++) {
          const file = files[i]
          const type = file.type.split('/')[1]
          let nameExt = file.name.match(/\.([0-9a-z]+)$/i)
          nameExt = nameExt.length > 1 ? nameExt[1] : '';
          if (![
            'pdf','jpg','jpeg','png', 'jps',
            'application/pdf','image/jpg',
            'image/jpeg','image/png', 'tiff'
          ].includes(type || nameExt)) {
            return this.$toast.error('Formato de arquivo não suportado!');
          }
          if (file.size > 3000000) {
            return this.$toast.error('Tamanho máximo de arquivo excedido!');
          }
          const id = uuidv4()
          this.filesList.unshift({
            id,
            name: file.name,
            custom_name: file.name,
            status: 'sending',
            size: file.size,
            error: false,
            progress: 0,
          })
          filesArray.push({
            id,
            file,
            type,
            custom_name: file.name,
            error: false,
            progress: 0,
          })
        }
      }
      this.filesToSend = filesArray

      if(this.filesToSend.length) await this.uploadFiles()
    },
    async uploadFiles() {
      this.uploading = true;
      if(this.filesToSend.length){
        for (let i = 0; i < this.filesToSend.length; i++) {
          const el = this.filesToSend[i]
          const customName = this.suitName(el.custom_name)
          const dataForm = new FormData();

          dataForm.append(`file`, el.file);
          dataForm.append(`custom_name`, customName);
          dataForm.append(`original_name`, el.custom_name);
          dataForm.append(`health_plan_id`, this.type === 'health_plan' ? this.healthPlanId : null);
          dataForm.append(`type`, this.type);
          dataForm.append(`clinic_id`, this.clinic_id);
          dataForm.append(`person_id`, getCurrentUser().id);

          const fileItem = this.filesList.find(f => f.id === el.id);
          if(fileItem){
            const minSize = 100 * 1024;
            const maxSize = 3 * 1024 * 1024;
            const minDelay = 100;
            const maxDelay = 500;

            const fileSize = fileItem.size;
            const delay = ((fileSize - minSize) / (maxSize - minSize)) * (maxDelay - minDelay) + minDelay;

            let progress = 0;
            const simulation = setInterval(() => {
              progress += Math.floor(Math.random() * 10) + 1;
              if (progress >= 100) {
                progress = 100;
                clearInterval(simulation);
              }
              fileItem.progress = progress;
            }, delay);

            await this.api.sendOrientationsFile(dataForm, {
              onUploadProgress: event => {
                fileItem.status = 'sending';
                fileItem.progress = Math.round((event.loaded * 100) / event.total);
              }
            }).then(({data}) => {
              fileItem.path = data.file.file_path;
              fileItem.id = data.file.id;
              fileItem.status = 'sent';
              fileItem.progress = 99;
              fileItem.progress = 100;
              clearInterval(simulation);
            }).catch(error => {
              fileItem.status = 'failed';
              fileItem.error = error.message;
              console.error('Erro ao enviar arquivo (API): ', error);
              clearInterval(simulation);
            }).finally(() => {
              this.uploading = false;
            });
          }
        }
      }
      this.$emit('uploadedFiles')
    },
    removeFile(file){
      const self = this
      this.$swal.fire({
        title: 'Excluir documento',
        text: 'Essa ação não poderá ser desfeita. Deseja realmente excluir este documento?',
        showCancelButton: true,
        confirmButtonColor: '#305BF2',
        confirmButtonText: 'Confirmar',
        cancelButtonColor: 'transparent',
        cancelButtonText: '<span style="color: red;">Cancelar</span>',
        cancelButtonHoverColor: '#ff0000',
        showCloseButton: true,
        reverseButtons: true,
        customClass: {
          popup: "custom-swal-clear-passwords",
          container: "custom-swal-container"
        },
      }).then((result) => {
        if (result.isConfirmed) {
          const index = this.filesList.findIndex(f => f === file);
          if(file.path && file.id){
            const removeLoading = self.$loading.show()
            this.api.deleteFile(file.path, file.id).then(() => {
              this.$toast.success('Arquivo excluído com sucesso!')
            }).catch(error => {
              console.error('Erro ao excluir arquivo (API): ', error)
              this.$toast.error('Erro ao excluir arquivo!')
            }).finally(() => {
              if (index !== -1) {
                this.filesList.splice(index, 1);
              }
              removeLoading.hide()
            })
          } else {
            if (index !== -1) {
              this.filesList.splice(index, 1);
            }
          }}
      })
    },
    returnFileSize(number) {
      if (number < 1024) {
        return `${number} bytes`;
      } else if (number >= 1024 && number < 1048576) {
        return `${(number / 1024).toFixed(1)} kB`;
      } else if (number >= 1048576) {
        return `${(number / 1048576).toFixed(1)} MB`;
      }
    },
    parseStatus(status) {
      switch (status) {
        case 'sent':
          return 'Enviado';
        case 'sending':
          return 'Enviando';
        case 'failed':
          return 'Falha no envio';
        default:
          return '';
      }
    },
    suitName(name) {
      return name
          .replace(/\.[0-9a-z]+$/i, '')
          .replace(/[^\w\sA-Za-zÀ-ÖØ-öø-ÿ-()]/g, '')
    },
    listFiles(){
      this.loading = true;
      const payload = {
        clinic_id: this.clinic_id,
        health_plan_id: this.type === 'health_plan' ? this.healthPlanId : null,
        type: this.type
      }
      this.api.getOrientationsFiles(payload).then(({data}) => {
        if(data.length){
          this.filesList = data.map(f => ({
            id: f.id,
            name: f.original_name,
            path: f.file_path,
            uploaded_by: f.person_name,
            uploaded_at: f.created_at
          }))
        }
      }).catch(error => {
        console.error('Erro ao buscar arquivos (API): ', error)
      }).finally(() => {
        this.loading = false;
      })
    },
    async showFile(path, fileName){
      if(!path) return
      const isLoading = this.$loading.show()
      try {
        const { data }  = await this.api.getDocTemporaryUrl(path);
        if (data.includes('.pdf')) {
          window.open(data, '_blank')
        } else {
          this.openFileModal(data, fileName);
        }
      } catch (error) {
        console.error('Erro ao buscar arquivo (API): ', error)
        this.$toast.error('Erro ao buscar arquivo!')
      } finally {
        isLoading.hide()
      }
    },
    async openFileModal(fileUrl, fileName) {
      this.$nextTick(() => {
        this.modalFileUrl = fileUrl;
        this.modalFileName = fileName;
        // this.$bvModal.show(`fileModal-${this.modalId}`);
        this.showModal = true;
      });
    },
    closeModal() {
      this.showModal = false;
    },
    openNewTab(file){
      this.showModal = false;
      window.open(file, '_blank');
    }
  },
}
</script>

<style lang="scss" scoped>
.section-title {
  font-family: 'Red Hat Display',serif;
  font-size: 20px;
  font-weight: 700;
  line-height: 26px;
}

.section-subtitle {
  font-family: 'Nunito Sans',serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

.list-only {
  box-shadow: unset !important;
  border-radius: unset !important;
  padding: unset !important;
}

.file-uploader {
  font-family: 'Nunito Sans',serif;
  display: flex;
  flex-direction: column;
  background-color: var(--neutral-000);
  border-radius: 8px;
  box-shadow: 0 2px 3px 0 #0000001F;
  padding: 16px;
  gap: 16px;

  &__list {
    width: 100%;
    display: flex;
    padding: 24px;
    align-items: center;
    gap: 16px;
    border: 1px solid lightgray;
    border-radius: 4px;
    justify-content: center;
  }

  .upload-icon {
    width: 10%;
    height: 24px;
    fill: #305BF2;
  }

  .upload-input-icon {
    width: 24px;
    height: 24px;
    fill: #305BF2;
  }

  .failed-status {
    fill: var(--danger);
    color: var(--danger);
  }

  &__title {
    display: flex;
    flex-direction: column;
  }

  &__container {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  &__input {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 24px;
    align-items: center;
    gap: 16px;
    border: 1px dashed lightgray;
    border-radius: 4px;

    label {
      cursor: pointer;
    }

    u {
      color: var(--blue-500);
    }

    .set-file-content-input {
      width: auto;
      height: auto;
      padding: 8px 16px;
      font-weight: 700;
      font-size: 12px;
      line-height: 130%;
      color: var(--neutral-000);
      border-radius: 8px;
      background: var(--blue-700);
      cursor: pointer;
    }

    #set-file-content-input {
      display: none;
    }
  }

  .file-history {
    display: flex;
    flex-direction: column;
    gap: 8px;

    &__item {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 16px;
      gap: 16px;
      border-radius: 4px;
      justify-content: space-between;
      box-shadow: 0 0 3px 2px rgba(0, 0, 0, 0.1215686275);
      cursor: pointer;

      &__id {
        display: flex;
        gap: 16px;
        align-items: center;

        .file-info {
          display: flex;
          flex-direction: column;
          gap: 4px;

          .file-status {
            font-size:  small;
          }
        }

        .file-sent {
          fill: white;
          background: var(--success);
          border-radius: 50%;
          box-sizing: border-box;
        }
      }

      &__icons {
        display: flex;
        gap: 16px;
        align-items: center;

        .delete-file {
          fill: rgba(0, 0, 0, 0.5);
          cursor: pointer;
          transform: scale(0.9);
          transition: 0.3s;

          &:hover {
            fill: rgba(0, 0, 0, 0.7);
          }

        }
      }
    }
  }
}

.modal {
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  font-family: "Nunito Sans", serif;
}

.modal-background {
  position: absolute;
  width: 100%;
  height: 100%;
}

.modal-content {
  position: relative;
  z-index: 10000;
  background-color: white;
  border-radius: 8px;
  max-width: 80%;
  max-height: 80%;
  overflow: auto;
}

.modal-header {
  font-size: 18px;
  font-weight: 600;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  border-bottom: 1px solid #ddd;
}

.modal-body {
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
  overflow: auto;

  img {
    max-width: 100%;
    max-height: 100%;
  }
}

.file-id {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 8px;

  span {
    font-size: 18px;
    font-weight: 600;
  }

  .open-new-tab {
    font-size: 16px;
    font-weight: 700;
    color: var(--primary);
    cursor: pointer;
  }
}

.modal-close {
  cursor: pointer;
  width: 20px;
  height: 20px;

  svg {
    stroke: lightgray;
  }
}

#fileModal {
  font-family: "Nunito Sans", serif;
}

#fileModal___BV_modal_outer_{
  z-index: 9999 !important;
}

.export-icon {
  margin: 5px;

  svg {
    width: 15px;
    height: 15px;
  }
}
</style>